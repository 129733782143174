<template>
  <div>
    <div class="sjfoot_bbox">
      <div class="sjfoot">
        <div class="sjfootbox">
          <div class="sjfoot_l">
            <img
              src="https://task.hozoin.cn/Works/20210201/sj-8373215090.png"
              alt=""
            />
            <div>
              <icon-svg icon-class="icondianhua" />
              <span>400-921-7065</span>
            </div>
            <div>
              <icon-svg icon-class="iconyouxiang" />
              <span>hozoin@hozoin.cn</span>
            </div>
          </div>
          <div class="sjfoot_c">
            <ul>
              <li
                v-for="(item, index) in qiye"
                :key="index"
                @click="helpclick(item.id, item.bigid)"
              >
                {{ item.name }}
              </li>
            </ul>
            <ul>
              <li
                v-for="(item, index) in user"
                :key="index"
                @click="helpclick(item.id, item.bigid)"
              >
                {{ item.name }}
              </li>
            </ul>
            <ul>
              <li
                v-for="(item, index) in jiaoyi"
                :key="index"
                @click="helpclick(item.id, item.bigid)"
              >
                {{ item.name }}
              </li>
            </ul>
            <ul>
              <li
                v-for="(item, index) in xieyi"
                :key="index"
                @click="helpclick(item.id, item.bigid)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="sjfoot_r">
            <img
              src="https://task.hozoin.cn/Works/20210408/sj-1954775346.png"
              alt=""
            />
            <p>和作营</p>
            <p>微信公众号</p>
          </div>
        </div>
      </div>
      <div class="sjfootbtm">
        Copyright © 2014-2021 和作营（ www.hozoin.cn ）版权所有 保留所有解释权
        <span><a href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备17053650号-4</a></span>
      </div>
    </div>
    <div class="home_ios_bottombox_bb">
      <div class="home_ios_bottombox_bbbx">
        <div class="home_ios_bottombox_bbl">
          <img
            src="https://task.hozoin.cn/Works/20210201/sj-0046609973.png"
            alt=""
          />
        </div>
        <div class="home_ios_bottombox_bbr">
          <p>
            400-921-7065
            <img
              src="https://task.hozoin.cn/Works/20201223/sj-5111250000.png"
              alt=""
            />
          </p>
          <p>
            hozoin@hozoin.cn
            <img
              src="https://task.hozoin.cn/Works/20201223/sj-0267839249.png"
              alt=""
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      qiye: [
        {
          name: "我是企业",
          id: "999",
          bigid: "55",
        },
        {
          name: "发布流程",
          id: "52",
          bigid: "55",
        },
        {
          name: "交易规则",
          id: "40",
          bigid: "55",
        },
        {
          name: "发布项目",
          id: "38",
          bigid: "55",
        },
        {
          name: "常见问题",
          id: "41",
          bigid: "55",
        },
      ],
      user: [
        {
          name: "我是设计师",
          id: "999",
          bigid: "54",
        },
        {
          name: "上传作品",
          id: "62",
          bigid: "54",
        },
        {
          name: "承接项目",
          id: "12",
          bigid: "54",
        },
        {
          name: "账户认证",
          id: "50",
          bigid: "54",
        },
        {
          name: "常见问题",
          id: "35",
          bigid: "54",
        },
      ],
      jiaoyi: [
        {
          name: "交易保障",
          id: "999",
          bigid: "56",
        },
        {
          name: "支付流程",
          id: "42",
          bigid: "56",
        },
        {
          name: "充值流程",
          id: "15",
          bigid: "56",
        },
        {
          name: "违规处理",
          id: "48",
          bigid: "57",
        },
        {
          name: "常见问题",
          id: "59",
          bigid: "56",
        },
      ],
      xieyi: [
        {
          name: "协议条款",
          id: "999",
          bigid: "58",
        },
        {
          name: "关于我们",
          id: "20",
          bigid: "58",
        },
        {
          name: "用户协议",
          id: "24",
          bigid: "58",
        },
        {
          name: "联系我们",
          id: "28",
          bigid: "58",
        },
        {
          name: "版权声明",
          id: "29",
          bigid: "58",
        },
      ],
    };
  },
  methods: {
    helpclick(id, big) {
      if (id != "999") {
        this.$router.push({ path: "/help", query: { data: id, big: big } });
        document.documentElement.scrollTop = 0;
      }
    },
  },
};
</script>
<style>
@import "../assets/css/foot.css";

* {
  margin: 0;
  padding: 0;
}
</style>