import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 存放状态
        name: "测试vuex  我是Vuex内容",
        timersesson: null,
        num: 0,
    },
    mutations: {
        // state成员操作
        gai(state, data) {
            console.log(data)
            state.name = "我是改变后的Vuex内容 id=" + data.data.id + ""
        },
        zeng() {
            // 动态增加新数据
            Vue.set(state, "age", 15)
        },
        shan() {
            // 动态删减新数据
            Vue.delete(state, 'age')
        },
    },
    actions: {
        // 异步操作
    },
    modules: {
        // 模块化状态管理
    }
})