export default function clearsession() {
    var num = 0;
    var timer = setInterval(() => {
        if (num < 10800) {
            num++;
            // console.log(num)
        } else {
            localStorage.removeItem("userinfo");
            clearInterval(timer);
        }
    }, 1000);
};