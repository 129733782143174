<template>
  <div id="app">
    <el-backtop></el-backtop>
    <el-button
      @click="backtop"
      class="back1"
      icon="el-icon-caret-top"
      circle
    ></el-button>
    <Sjheader v-if="header" />
    <router-view />
    <Sjfoot v-if="header || foot" />
  </div>
</template>
<script src="http://res.wx.qq.com/open/js/jweixin-1.2.0.js"></script>
<script>
import Sjheader from "@/components/Sjheader.vue";
import Sjfoot from "../src/components/Sjfoot.vue";

export default {
  components: {
    Sjheader,
    Sjfoot,
  },
  data() {
    return {
      header: true,
      foot: true,
      userinfo: {},
    };
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    let width = document.documentElement.clientWidth;
    let widths = Number(width / 138.1);
    if (widths < 12) {
      document.documentElement.style.fontSize = "12px";
    } else {
      document.documentElement.style.fontSize = "" + widths + "px";
    }
  },
  watch: {
    $route(to, from) {
      var that = this;
      var topath = to.path;
      var frompath = from.path;
      document.documentElement.scrollTop = 0;
      if (topath == "/Sjlogin") {
        that.header = false;
        that.foot = false;
      }
      if (topath == "/Sjzhuce") {
        that.header = false;
        that.foot = false;
      }
      if (topath == "/Chengguo_cworkszp") {
        that.header = false;
        that.foot = true;
      }
      if (topath == "/Chengguo_c") {
        that.header = true;
      }

      if (frompath == "/Sjlogin" || frompath == "/Sjzhuce") {
        that.header = true;
        that.foot = true;
        if (topath == "/Sjlogin" || topath == "/Sjzhuce") {
          that.header = false;
          that.foot = false;
        }
      }
      if (frompath == "/Chengguo_cworkszp") {
        that.header = true;
        that.foot = true;
        if (topath == "/Chengguo_cworkszp") {
          that.header = false;
          that.foot = true;
        }
      }
    },
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        let width = document.documentElement.clientWidth;
        let widths = Number(width / 138.1);
        if (widths < 12) {
          document.documentElement.style.fontSize = "12px";
        } else {
          document.documentElement.style.fontSize = "" + widths + "px";
        }
      })();
    };
  },

  methods: {
    backtop() {
      var timer = setInterval(function () {
        //获取滚动条距离顶部的高度
        var osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var ispeed = Math.floor(-osTop / 10);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;

        if (osTop == 0) {
          clearInterval(timer);
        }
      }, 10);
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
  text-decoration: none;
}
.clicknone {
  pointer-events: none;
}
.Zhanping_bisheullizero {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}
</style>
