import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
        meta: {
            title: "和作营（hozoin.cn）— 国内首家一站式设计托管服务平台",
        },
    },
    {
        path: '/shejihd',
        name: 'shejihd',
        component: () =>
            import ('../views/shejihd.vue'),
        meta: {
            title: "和作营-设计活动",
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () =>
            import ('../components/help.vue'),
        meta: {
            title: "和作营-帮助中心",
        },

    },
    {
        path: '/Rencai_jl',
        name: 'Rencai_jl',
        component: () =>
            import ('../views/Rencai_jl.vue'),
        meta: {
            title: "和作营-优秀设计师简历",
        },

    },
    {
        path: '/Userhl_favideohj',
        name: 'Userhl_favideohj',
        component: () =>
            import ('../components/Userhomeleft/Userhl_favideohj.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Userhl_favideo_s',
        name: 'Userhl_favideo_s',
        component: () =>
            import ('../components/Userhomeleft/Userhl_favideo_s.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/shejihd_c',
        name: 'shejihd_c',
        component: () =>
            import ('../views/shejihd_c.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Xiangmu',
        name: 'Xiangmu',
        component: () =>
            import ('../views/Xiangmu.vue'),
        meta: {
            title: "和作营-项目大厅",
        },

    },
    {
        path: '/Xiangmu_c',
        name: 'Xiangmu_c',
        component: () =>
            import ('../views/Xiangmu_c.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Xiangmu_c_tx',
        name: 'Xiangmu_c_tx',
        component: () =>
            import ('../views/Xiangmu_c_tx.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Zhanping',
        name: 'Zhanping',
        component: () =>
            import ('../views/Zhanping.vue'),
        meta: {
            title: "和作营-线上展览",
        },

    },
    {
        path: '/Zhanping_bishe',
        name: 'Zhanping_bishe',
        component: () =>
            import ('../views/Zhanping_bishe.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Chengguo',
        name: 'Chengguo',
        component: () =>
            import ('../views/Chengguo.vue'),
        meta: {
            title: "和作营-成果转化",
        },

    },
    {
        path: '/Chengguo_c',
        name: 'Chengguo_c',
        component: () =>
            import ('../views/Chengguo_c.vue'),
        meta: {
            title: "和作营",
        },

    },

    {
        path: '/Chengguo_cworkszp',
        name: 'Chengguo_cworkszp',
        component: () =>
            import ('../views/Chengguo_cworkszp.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Rencai',
        name: 'Rencai',
        component: () =>
            import ('../views/Rencai.vue'),
        meta: {
            title: "和作营-设计师",
        },

    },
    {
        path: '/Rencai_c',
        name: 'Rencai_c',
        component: () =>
            import ('../views/Rencai_c.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Jiaoyu',
        name: 'Jiaoyu',
        component: () =>
            import ('../views/Jiaoyu.vue'),
        meta: {
            title: "和作营-教育视频",
        },

    },
    {
        path: '/Jiaoyu_heji',
        name: 'Jiaoyu_heji',
        component: () =>
            import ('../views/Jiaoyu_heji.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Jiaoyu_c',
        name: 'Jiaoyu_c',
        component: () =>
            import ('../views/Jiaoyu_c.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Zixun',
        name: 'Zixun',
        component: () =>
            import ('../views/Zixun.vue'),
        meta: {
            title: "和作营-平台资讯",
        },
    },
    {
        path: '/Zixun_c',
        name: 'Zixun_c',
        component: () =>
            import ('../views/Zixun_c.vue'),
        meta: {
            title: "和作营",
        },
    },
    {
        path: '/Zhuanjia',
        name: 'Zhuanjia',
        component: () =>
            import ('../views/Zhuanjia.vue'),
        meta: {
            title: "和作营-专家库",
        },

    },
    {
        path: '/Zhuanjia_c',
        name: 'Zhuanjia_c',
        component: () =>
            import ('../views/Zhuanjia_c.vue'),
        meta: {
            title: "和作营",
        },

    },
    {
        path: '/Sjlogin',
        name: 'Sjlogin',
        component: () =>
            import ('../views/Sjlogin.vue'),
        meta: {
            title: "和作营-登录",
        },

    },
    {
        path: '/Sjzhuce',
        name: 'Sjzhuce',
        component: () =>
            import ('../views/Sjzhuce.vue'),
        meta: {
            title: "和作营-注册",
        },

    },
    {
        path: '/Userhome',
        name: 'Userhome',
        component: () =>
            import ('../views/Userhome.vue'),
        meta: {
            title: "和作营-个人中心",
        },
        children: [{
                path: '/',
                name: 'Userhl_home',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_home.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }, {
                path: '/Userhome/Userhl_info',
                name: 'Userhl_info',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_info.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }, {
                path: '/Userhome/Userhl_baoming',
                name: 'Userhl_baoming',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_baoming.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_msg',
                name: 'Userhl_msg',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_msg.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_faburenwu',
                name: 'Userhl_faburenwu',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_faburenwu.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }, {
                path: '/Userhome/Userhl_fapiao',
                name: 'Userhl_fapiao',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_fapiao.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }, {
                path: '/Userhome/Userhl_fapiaolie',
                name: 'Userhl_fapiaolie',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_fapiaolie.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }, {
                path: '/Userhome/Userhl_jianli',
                name: 'Userhl_jianli',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_jianli.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }, {
                path: '/Userhome/Userhl_jianli_q',
                name: 'Userhl_jianli_q',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_jianli_q.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }, {
                path: '/Userhome/Userhl_jiaoyi',
                name: 'Userhl_jiaoyi',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_jiaoyi.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_dingdan',
                name: 'Userhl_dingdan',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_dingdan.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }, {
                path: '/Userhome/Userhl_mezp',
                name: 'Userhl_mezp',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_mezp.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_zhanlan',
                name: 'Userhl_zhanlan',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_zhanlan.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_ygkecheng',
                name: 'Userhl_ygkecheng',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_ygkecheng.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_zuopin',
                name: 'Userhl_zuopin',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_zuopin.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_msg',
                name: 'Userhl_msg',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_msg.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_gfc',
                name: 'Userhl_gfc',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_gfc.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_shoucang',
                name: 'Userhl_shoucang',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_shoucang.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            },
            {
                path: '/Userhome/Userhl_videogl',
                name: 'Userhl_videogl',
                component: () =>
                    import ('../components/Userhomeleft/Userhl_videogl.vue'),
                meta: {
                    title: "和作营-个人中心",
                },
            }
        ]
    }, {
        path: '/Userhl_fazp',
        name: 'Userhl_fazp',
        component: () =>
            import ('../components/Userhomeleft/Userhl_fazp.vue'),
        meta: {
            title: "和作营-发布展评",
        },
    }, {
        path: '/Userhl_shangchuan',
        name: 'Userhl_shangchuan',
        component: () =>
            import ('../components/Userhomeleft/Userhl_shangchuan.vue'),
        meta: {
            title: "和作营-发布作品",
        },
    },
    {
        path: '/Userhl_faxiangmu',
        name: 'Userhl_faxiangmu',
        component: () =>
            import ('../components/Userhomeleft/Userhl_faxiangmu.vue'),
        meta: {
            title: "和作营-发布项目",
        },
    },
    {
        path: '/Userhl_favideo',
        name: 'Userhl_favideo',
        component: () =>
            import ('../components/Userhomeleft/Userhl_favideo.vue'),
        meta: {
            title: "和作营-发布教育视频",
        },
    },
    {
        path: '/Sjfabu',
        name: 'Sjfabu',
        component: () =>
            import ('../components/Sjfabu.vue'),
        meta: {
            title: "和作营-发布",
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach((to, from, next) => {
    document.title = to.matched[0].meta.title;
    next();
});

export default router