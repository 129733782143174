<template>
  <div>
    <div class="Sjlogintype">
      <div class="sjtopallboxlogin" v-if="userinfotype == false">
        <div class="sjtopallboxlogin_f">
          <span style="background: transparent">
            <router-link to="/Sjlogin">登录</router-link>
          </span>
        </div>
        <div class="sjtopallboxlogin_fxian"></div>
        <div class="sjtopallboxlogin_f">
          <span style="background: transparent">
            <router-link to="/Sjzhuce">注册</router-link>
          </span>
        </div>
      </div>
      <div
        class="sjtopallboxlogin"
        v-if="userinfotype == true"
        style="width: 12.7vw; flex-wrap: wrap"
      >
        <div style="width: auto">
          <span style="border: none; margin: 0 auto; width: 3.3vw">
            <router-link
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 2vw;
                height: 2vw;
                border: 1px solid rgb(177 177 177);
                border-radius: 50%;
              "
              to="/Userhome/Userhl_msg"
              v-html="newmsg"
            >
            </router-link>
          </span>
        </div>
        <div
          class="sjtopallboxlogin2mn"
          style="width: 3.2vw"
          @mouseenter="enter"
          @mouseleave="leave"
        >
          <span class="sjtopallboxlogin2mnspanimg">
            <router-link to="/Userhome">
              <img
                class="sjtopallboxloginimg"
                style="width: 2.2vw; height: 2.2vw"
                id="user_npcimg"
                :src="userimg"
                alt=""
              />
            </router-link>
          </span>
          <div class="sjtopallboxlogin_zhankai" v-show="zk">
            <div class="sjtopallboxlogin_zkbx">
              <div class="sjtopallboxlogin_zkbx_t">
                账户余额<span>￥{{ usermoney }}</span
                ><span><router-link to="/Userhome">提现</router-link></span>
              </div>
              <ul class="sjtopallboxlogin_zkbx_ul">
                <li>
                  <router-link to="/Userhome/Userhl_info"
                    >编辑个人资料</router-link
                  >
                </li>
              </ul>
              <ul class="sjtopallboxlogin_zkbx_ul">
                <li :style="tnone">
                  <router-link to="/Userhome/Userhl_baoming"
                    >我的任务</router-link
                  >
                </li>
                <li>
                  <router-link to="/Userhome/Userhl_ygkecheng"
                    >我的课堂</router-link
                  >
                </li>
                <li>
                  <router-link to="/Userhome/Userhl_mezp">我的活动</router-link>
                </li>
                <li>
                  <router-link to="/Userhome/Userhl_shoucang"
                    >我的收藏</router-link
                  >
                </li>
              </ul>
              <ul class="sjtopallboxlogin_zkbx_ul" style="border: none">
                <li>
                  <router-link to="/Userhome/Userhl_info">修改密码</router-link>
                </li>
                <li @click="login_out">退出</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="sjtopallboxlogin_f" style="width: 3.66vw">
          <span class="sjtopallboxlogin_ffab" style="margin-top: 6px;padding:0;border:none;">
            <router-link :to="fabu" style="border: 1px solid rgb(252,151,50);">
              {{ caozuo }}
            </router-link>
          </span>
        </div>
      </div>
    </div>
    <div class="sjtop_ioscenter_dl_box">
      <div class="sjtop_ioscenter_dl" v-if="userinfotype == false">
        <router-link to="/Sjlogin">登录/注册</router-link>
      </div>
      <div v-if="userinfotype == true" class="sjtop_ioscenter_dl_boxflex">
        <div class="sjtop_ioscenter_dltrue" style="margin-right: 1.2rem">
          <router-link to="/Userhome">
            <img
              src="https://task.hozoin.cn/Works/20201017/sj-9504730699.png"
              alt=""
            />
          </router-link>
          <div class="iosmsgd_home" style="display: block"></div>
        </div>
        <div class="sjtop_ioscenter_dltrue">
          <router-link :to="fabu">
            <img
              src="https://task.hozoin.cn/Works/20201027/sj-6305755452.png"
              alt=""
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userinfotype: false,
      newmsg: "",
      newmsgfalse:
        "<img class='sjtopallboxloginimg' style='width: 1.2vw;height: auto;margin: 0 auto;border-radius:0;margin-left: 0.5vw;' src='https://task.hozoin.cn/Works/20201116/sj-5863121025.png' alt=''>", //已读
      newmsgtrue:
        "<img class='sjtopallboxloginimg' style='width: 1.2vw;height: auto;margin: 0 auto;border-radius:0;margin-left:0.4vw;' src='https://task.hozoin.cn/Works/20201116/sj-2899690840.png' alt=''>", //未读
      tnone: "display:block",
      zk: false,
      caozuo: "",
      userimg: "https://task.hozoin.cn/Works/20210402/sj-0768621535.png",
      usermoney: "0.00",
      fabu: "/",
      userinfo: {},
    };
  },
  methods: {
    login_out() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/logout",
        method: "post",
        data: {},
      })
        .then((res) => {
          if (res.data.code == 1) {
            that.$message(res.data.messages);
            localStorage.removeItem("userinfo");
            this.$router.push({ path: "/Sjlogin" });
          } else {
            that.$message("已退出");
            localStorage.removeItem("userinfo");
            this.$router.push({ path: "/Sjlogin" });
          }
        })
        .catch((err) => {
          that.$message("已退出");
            localStorage.removeItem("userinfo");
            this.$router.push({ path: "/Sjlogin" });
        });
    },
    enter() {
      this.zk = true;
    },
    leave() {
      this.zk = false;
    },
    shishi() {
      var that = this;
      if (that.userinfo != null) {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getUserInfoData",
          method: "post",
          data: {},
        }).then((res) => {
          res.data.result.token = this.userinfo.token;
          localStorage.removeItem("userinfo");
          var checkedIdStr = JSON.stringify(res.data.result);
          localStorage.setItem("userinfo", checkedIdStr);
          this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
          this.tokentimeyz();
        });
      }
    },
    zhanshiall() {
      var that = this;
      if (this.userinfo) {
        that.userinfotype = true;
        that.fabu = "/Sjfabu";
        that.usermoney = that.userinfo.can_money;
        if (this.userinfo.groupid == 1) {
          that.caozuo = "发布";
          // that.fabu = "/Userhl_shangchuan";
        } else if (this.userinfo.groupid == 2) {
          that.caozuo = "发布";
          // that.fabu = "/Userhl_faxiangmu";
        } else if (this.userinfo.groupid == 3) {
          that.caozuo = "发布";
          that.tnone = "display:none";
          // that.fabu = "/Userhl_favideo";
        } else if (this.userinfo.groupid == 4) {
          that.caozuo = "我的";
          that.fabu = "/";
        }
        if (this.userinfo.unread != 0) {
          that.newmsg = that.newmsgfalse;
        } else {
          that.newmsg = that.newmsgtrue;
        }
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getUserInfoData",
          method: "post",
          data: {},
        }).then((res) => {
          if (
            res.data.result.userpic == null ||
            res.data.result.userpic == ""
          ) {
            //用户未上传头像 默认图
          } else {
            that.userimg = res.data.result.userpic;
          }
        });
      }
    },
  },
  created() {
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.userinfo = userinfo;
    this.zhanshiall();
  },
  watch: {
    $route(to, from) {
      this.shishi();
    },
    userinfo() {
      this.zhanshiall();
    },
  },
};
</script>
<style>
.Sjlogintype {
  width: 13vw;
}
</style>