<template>
  <div>
    <div class="homeheader">
      <div class="homeheaderbox" :class="headerclass">
        <div class="sjtopallboxlogo">
          <router-link to="/"
            ><img
              src="https://img.hozoin.cn/news_img/20210419/db03c298445119ede0156046bd2bd865.png"
              alt=""
          /></router-link>
        </div>
        <div class="sjtopallboxmean">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="transparent"
            text-color="#D0D0D0"
            active-text-color="#fff"
          >
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">接单</el-menu-item>
            <el-menu-item index="3">展赛</el-menu-item>
            <!-- <el-menu-item index="4">活动</el-menu-item> -->
            <el-menu-item index="5">课程</el-menu-item>
            <el-menu-item index="6">资讯</el-menu-item>
            <el-submenu index="7" class="sjtopallboxmeanuser">
              <template slot="title">设计师</template>
              <el-menu-item index="7-1">
                <router-link class="pcntopa" to="/Rencai">
                  <div class="sjtopallboxmeanitem sjtopallboxmeanitem1">
                    <div
                      class="sjtopallboxmeanitemicon sjtopallboxmeanitemicon1"
                    >
                      <img
                        src="https://task.hozoin.cn/Works/20201208/sj-9825964461.png"
                        alt=""
                      />
                      <img
                        src="https://task.hozoin.cn/Works/20201208/sj-9825964461.png"
                        alt=""
                      />
                    </div>
                    <div class="sjtopallboxmeanitemtext">
                      <p>设计师</p>
                      <p>专业设计人才的集中展示</p>
                    </div>
                  </div>
                </router-link>
              </el-menu-item>
              <el-menu-item index="7-2">
                <router-link class="pcntopa" to="/Chengguo">
                  <div class="sjtopallboxmeanitem sjtopallboxmeanitem3">
                    <div
                      class="sjtopallboxmeanitemicon sjtopallboxmeanitemicon3"
                    >
                      <img
                        src="https://task.hozoin.cn/Works/20201208/sj-1470451426.png"
                        alt=""
                      />
                      <img
                        src="https://task.hozoin.cn/Works/20201208/sj-1470451426.png"
                        alt=""
                      />
                    </div>
                    <div class="sjtopallboxmeanitemtext">
                      <p>成果转化</p>
                      <p>设计思维与专业技能的全力展现</p>
                    </div>
                  </div>
                </router-link>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="8">专家</el-menu-item>
          </el-menu>
        </div>
        <Sjlogintype />
      </div>
    </div>
    <div class="sjtop_ios">
      <div class="sjtop_ioscenter">
        <icon-svg slot="reference" icon-class="iconcaidan" />
        <div class="shouyetanbtnbox">
          <van-cell @click="showPopup(showPopupnum)"> </van-cell>
        </div>
        <div class="sjtop_ioscenter_logo">
          <img
            src="https://task.hozoin.cn/Works/20210507/sj-9271148951.png"
            alt=""
            @click="homeclick"
          />
        </div>
        <Sjlogintype />
      </div>
    </div>
    <van-popup
      class="van-popup--leftheader"
      v-model="show"
      position="left"
      :style="prostyle"
    >
      <ul class="vantheaderprobox">
        <li
          class="vantheaderprobox_t"
          v-for="(item, index) in popupli"
          :key="index"
        >
          <router-link class="pcntopa" :to="item.u">
            {{ item.t }}
          </router-link>
        </li>
      </ul>
    </van-popup>
  </div>
</template>
<script>
import Sjlogintype from "@/components/Sjlogintype.vue";
import Util from "../assets/js/Util";
export default {
  components: {
    Sjlogintype,
  },
  data() {
    return {
      show: false,
      activeIndex:'1',
      userinfo: {},
      headerclass: "",
      prostyle: {
        width: "30%",
        height: "100%",
        // top: "55%",
      },
      showPopupnum: 0,
      popupli: [
        {
          t: "首页",
          u: "/",
        },
        {
          t: "展赛",
          u: "/Zhanping",
        },
        {
          t: "接单",
          u: "/Xiangmu",
        },
        {
          t: "资讯",
          u: "/Zixun",
        },
        {
          t: "人才",
          u: "/Rencai",
        },
        {
          t: "作品",
          u: "/Chengguo",
        },
        {
          t: "专家",
          u: "/Zhuanjia",
        },
        {
          t: "课程",
          u: "/Jiaoyu",
        },
      ],
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      if (key == "1") {
        this.$router.push({ path: "/" });
      } else if (key == "2") {
        this.$router.push({ path: "/Xiangmu" });
      } else if (key == "3") {
        this.$router.push({ path: "/Zhanping" });
      } else if (key == "5") {
        this.$router.push({ path: "/Jiaoyu" });
      } else if (key == "6") {
        this.$router.push({ path: "/Zixun" });
      } else if (key == "8") {
        this.$router.push({ path: "/Zhuanjia" });
      }
    },
    showPopup(id) {
      if (id == 0) {
        this.show = true;
        this.showPopupnum = 1;
      } else {
        this.show = false;
        this.showPopupnum = 0;
      }
    },
    homeclick() {
      this.$router.push({ path: "/" });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    var path = this.$route.path;
    if (path == "/") {
      this.headerclass = "headerclass";
    }
  },
  mounted() {
    Util();
  },
  watch: {
    $route(to) {
      var path = to.path;
      if (path == "/Xiangmu" || path == "/Xiangmu_C") {
        this.activeIndex = "2";
      } else if (path == "/Zhanping" || path == "/Zhanping_bishe") {
        this.activeIndex = "3";
      } else if (path == "/shejihd" || path == "/shejihd_c") {
        this.activeIndex = "3";
      } else if (
        path == "/Jiaoyu" ||
        path == "/jiaoyu_heji" ||
        path == "/Jiaoyu_c"
      ) {
        this.activeIndex = "5";
      } else if (path == "/Zixun" || path == "/Zixun_C") {
        this.activeIndex = "6";
      } else if (path == "/Rencai" || path == "/Rencai_c") {
        this.activeIndex = "7-1";
      } else if (path == "/Chengguo" || path == "/Chengguo_c") {
        this.activeIndex = "7-2";
      } else if (path == "/Zhuanjia" || path == "/Zhuanjia_c") {
        this.activeIndex = "8";
      } else {
        this.activeIndex = "9";
      }
      ////////////////////////
      this.show = false;
      this.showPopupnum = 0;
      document.documentElement.scrollTop = 0;
      if (to.path == "/") {
        this.headerclass = "headerclass";
      } else {
        this.headerclass = "";
      }
    },
  },
};
</script>
<style>
@import "../assets/css/header.css";

* {
  margin: 0;
  padding: 0;
}
</style>
