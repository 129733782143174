import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import md5 from './md5/index.js'
import Vant from 'vant';
import 'vant/lib/index.css';
import echarts from 'echarts';
import VueLazyLoad from 'vue-lazyload'; // 懒加载
Vue.use(VueLazyLoad, {
    error: 'https://img.hozoin.cn/news_img/20210520/16214984560001568.jpg', // 加载错误的图片
    loading: 'https://img.hozoin.cn/news_img/20210520/16214982650001568.gif' // 加载时的图片
});
Vue.use(VueLazyLoad)
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
require("echarts/lib/chart/radar");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/radar");
Vue.prototype.$echarts = echarts
Vue.use(Vant);
import IconSvg from '@/components/icon'
Vue.component('icon-svg', IconSvg)
Vue.config.productionTip = false;
Vue.use(md5);
Vue.use(ElementUI);
import 'babel-polyfill'
import axios from 'axios'
if (process.env.NODE_ENV === "development") {
    //生产环境
    axios.defaults.baseURL = "/href";
} else {
    //线上环境
    axios.defaults.baseURL = "https://api.hozoin.cn";
};
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
    //分享、、、、、、、、、、、、、、、、、、、、、、、、、、、
import wx from 'weixin-js-sdk'
Vue.prototype.fenxiang = function(fxtitle, fxdesc, fximg) {
    let url = window.location.href;
    this.$axios({
        url: "/api/getWechatToken",
        method: "post",
        data: {
            url: url,
        },
    }).then((res) => {
        if (res.data.code == 1) {
            if (wx != undefined) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.data.result.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
                    timestamp: res.data.result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.result.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.result.signature, // 必填，签名，见附录1
                    jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage", "updateTimelineShareData", "updateAppMessageShareData"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(function(res) {
                    document.addEventListener("WeixinJSBridgeReady", function() {
                        document.getElementById('myvideo').play();

                    }, false);

                    //分享到朋友圈
                    wx.onMenuShareTimeline({
                        title: fxtitle, // 分享时的标题
                        link: url, // 分享时的链接
                        imgUrl: fximg, // 分享时的图标
                        success: function() {
                            // alert("分享成功");
                        },
                        cancel: function() {
                            // alert("取消分享");
                        }
                    });
                    wx.updateTimelineShareData({
                            title: fxtitle, // 分享时的标题
                            link: url, // 分享时的链接
                            imgUrl: fximg, // 分享时的图标
                            success: function() {
                                // alert("分享成功");
                            },
                            cancel: function() {
                                // alert("取消分享");
                            }
                        })
                        //分享给朋友
                    wx.onMenuShareAppMessage({
                        title: fxtitle,
                        desc: fxdesc,
                        link: url,
                        imgUrl: fximg,
                        success: function() {
                            // alert("分享成功");
                        },
                        cancel: function() {
                            // alert("取消分享");
                        }
                    });
                    wx.updateAppMessageShareData({
                        title: fxtitle,
                        desc: fxdesc,
                        link: url,
                        imgUrl: fximg,
                        success: function() {
                            // alert("分享成功");
                        },
                        cancel: function() {
                            // alert("取消分享");
                        }
                    })
                });
            } else {
                // console.log("不是微信浏览器")
            }
        } else {}
    });
};
//分享结束、、、、、、、、、、、、、、、、、、、、、、、、、、、

// 登录过期//////////////////////////////////////////////
Vue.prototype.tokentimeyz = function() {
    var tokentime = Number(JSON.parse(localStorage.getItem("tokentime")));
    var tokenalltime = tokentime + 82800000; //23小时
    var nowtime = Number(Date.now());
    if (nowtime < tokenalltime) {
        //token未过期
    } else {
        if (JSON.parse(localStorage.getItem("userinfo")) == null && JSON.parse(localStorage.getItem("tokentime")) == null) {
            //已清空 不刷新
        } else {
            localStorage.removeItem("userinfo");
            localStorage.removeItem("tokentime");
            setTimeout(function() {
                location.reload();
            }, 1000);
        };
    };
    var userinfoallqj = JSON.parse(localStorage.getItem("userinfo"));
    if (userinfoallqj != null) {
        this.$axios({
            headers: { "X-CSRF-TOKEN": userinfoallqj.token },
            url: "/api/getUserInfoData",
            method: "post",
            data: {},
        }).then((res) => {
            if (res.data.code > 0) {

            } else {
                localStorage.removeItem("userinfo");
                localStorage.removeItem("tokentime");
                setTimeout(function() {
                    location.reload();
                }, 1000);
            };
        }).catch((err) => {
            localStorage.removeItem("userinfo");
            localStorage.removeItem("tokentime");
            setTimeout(function() {
                location.reload();
            }, 1000);
        });
    }

};
// 登录过期//////////////////////////////////////////////


new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')